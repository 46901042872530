import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { authProtected } from '../../utilities/auth';
import { DsiSidebar as Sidebar, FlexRow, FlexCol } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { fetchCart, resetCart } from '../Cart/cartSlice';
import {
  customerFinancialInfoSelector,
  customerInfoSelector,
  fetchCustomerDetails,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import { currencySelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  children: any;
  userData: ThenArg<typeof API.getCustomerDetails>;
  currency: string;
  userFinancialData: any;
}

class MyAccount extends React.Component<Props & WithRouterProps> {
  public render() {
    const {
      location: { pathname },
    } = this.props;
    const { user, children } = this.props;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: __('Moj účet') }} />
          {!pathname.includes(
            __r('routes:moj-ucet/objednavka', '/moj-ucet/objednavka'),
          ) && this.renderSidebar()}
          <ContentWrapper
            fullWidth={pathname.includes(
              __r('routes:moj-ucet/objednavka', '/moj-ucet/objednavka'),
            )}
          >
            {user ? children : null}
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }

  public renderSidebar = () => {
    const {
      location: { pathname },
      userData,
      currency,
      userFinancialData,
      user,
    } = this.props;
    const isB2C = user && !user.b2b;
    const items: any[] = [];
    if (!isB2C) {
      items.push({
        name: __('Dashboard'),
        url: __r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard'),
          ) ||
          pathname === __r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard'),
      });
    }
    items.push(
      ...[
        {
          name: __('Faktúry a dobropisy'),
          url: __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
          active:
            pathname.includes(
              __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
            ) ||
            pathname === __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
        },
        {
          name: __('Záväzky'),
          url: __r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky'),
          active:
            pathname.includes(
              __r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky'),
            ) ||
            pathname === __r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky'),
        },
        {
          name: __('Moje nákupy'),
          url: __r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy'),
          active:
            pathname.includes(
              __r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy'),
            ) ||
            pathname ===
              __r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy'),
        },
      ],
    );
    if (!isB2C) {
      items.push({
        name: __('Backorder'),
        url: __r('routes:moj-ucet/backorder', '/moj-ucet/backorder'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/backorder', '/moj-ucet/backorder'),
          ) ||
          pathname === __r('routes:moj-ucet/backorder', '/moj-ucet/backorder'),
      });
    }
    items.push(
      ...[
        {
          name: __('Objednávky'),
          url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
          active:
            pathname.includes(
              __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
            ) ||
            pathname ===
              __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
        },
        // {
        //   name: __('Obľúbené'),
        //   url: __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene'),
        //   active: pathname.includes(
        //     __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene'),
        //   ),
        // },
        // {
        //   name: __('Strážny pes'),
        //   url: __r('routes:moj-ucet/strazny-pes', '/moj-ucet/strazny-pes'),
        //   active: pathname.includes(
        //     __r('routes:moj-ucet/strazny-pes', '/moj-ucet/strazny-pes'),
        //   ),
        // },
        {
          name: user && user.b2b ? __('Moje údaje') : __('Moje údaje B2C'),
          url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
          active: pathname.includes(
            __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
          ),
        },
        {
          name:
            user && user.b2b ? __('Moje dodacie adresy') : __('Moje adresy'),
          url: __r(
            'routes:moj-ucet/moje-dodacie-adresy',
            '/moj-ucet/moje-dodacie-adresy',
          ),
          active: pathname.includes(
            __r(
              'routes:moj-ucet/moje-dodacie-adresy',
              '/moj-ucet/moje-dodacie-adresy',
            ),
          ),
        },
        /*
        {
          name: __('Zmena hesla'),
          url: __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
          active: pathname.includes(
            __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
          ),
        },
        */

        {
          name: 'Nastavenia',
          url: __r('routes:moj-ucet/nastavenia', '/moj-ucet/nastavenia'),
          active: pathname.includes(
            __r('routes:moj-ucet/nastavenia', '/moj-ucet/nastavenia'),
          ),
        },
      ],
    );

    return (
      <Sidebar
        withoutLogout={true}
        handleLogout={this.logoutUser}
        items={items}
        userData={userData}
        currency={currency}
        userFinancialData={userFinancialData}
      />
    );
  };

  public logoutUser = () => {
    this.props.dispatch(
      logoutUser(true, () => this.props.dispatch(resetCart(false)), '/'),
    );
    // this.props.dispatch(push(__r('routes:prihlasenie', '/prihlasenie')));
    this.props.dispatch(fetchCart());
  };
}

const ContentWrapper = styled(FlexCol)<{ fullWidth }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '75%')};

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     width: 100%;
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0 ${rem(24)} ${rem(56)};
  justify-content: flex-start;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(16)} ${rem(32)};
  `}
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(90%);
  }
  flex: 0 1 auto;
  border: none;
  border-radius: 0 ${rem(2)} ${rem(2)} 0;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  height: ${rem(48)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  max-width: ${rem(320)};

  padding: ${rem(15)} ${rem(32)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    userData: customerInfoSelector(state),
    currency: currencySelector(state),
    userFinancialData: customerFinancialInfoSelector(state),
  };
};

const MyAccountProtected = withRouter(authProtected(MyAccount));
// const MyAccountProtected = MyAccount;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyAccount' })(MyAccountProtected),
);
