import React from 'react';
import { FlexCol, TextP, DsiSvgIcon as SvgIcon } from 'eshop-defaults';
import styled from '../../theme/styled-components';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import { Link } from 'react-router';
import { rem } from 'polished';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { __r } from 'react-i18n/lib';
import { fetchCart, resetCart } from '../../containers/Cart/cartSlice';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { Loader } from '../_helpers/Loader/Loader';
import { resolveUserArePricesLoading } from '../../utilities/user';
import { removeCartCookie } from '../../containers/Cart/helpers';

interface Props {
  user: any;
  dispatch: (action: any) => void;
}

function HeaderProfile({ user, dispatch }: Props) {
  const handleLogoutUser = () => {
    // dispatch(logoutUser());
    // removeCartCookie();
    // dispatch(resetCart(false));
    // dispatch(fetchCart());

    logoutRedux(dispatch).then(() => {
      // console.log('third');
      setTimeout(() => {
        if (window) {
          window.location.reload();
        }
      }, 500);
    });
  };

  const logoutRedux = dispatch =>
    new Promise((resolve, reject) => {
      dispatch(logoutUser(true, () => dispatch(resetCart(false)), '/'));
      removeCartCookie();
      dispatch(resetCart(false));
      // dispatch(fetchCart());
      resolve(null);
    });

  const isLoadingPrices = resolveUserArePricesLoading(user);
  const profileLink =
    user && user.b2b
      ? __r('routes:moj-ucet', '/moj-ucet')
      : __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky');

  return (
    <Wrapper>
      <DesktopLogoutWrapper>
        <LinkLoginWrapper to={profileLink}>
          <SvgIcon icon={IconType.user} alt="user-icon" cursor={'pointer'} />
          {isLoadingPrices && (
            <MobileLoaderWrapper title={__('Prebieha načítanie cien')}>
              <Loader small={true} primaryColor={true} />
            </MobileLoaderWrapper>
          )}
        </LinkLoginWrapper>
      </DesktopLogoutWrapper>
      <MobileLogoutWrapper>
        <LinkLoginWrapper to={profileLink}>
          <SvgIcon icon={IconType.user} alt="user-icon" cursor={'pointer'} />
          {/* {isLoadingPrices && (
            <MobileLoaderWrapper title={__('Prebieha načítanie cien')}>
              <Loader small={true} primaryColor={true} />
            </MobileLoaderWrapper>
          )} */}
        </LinkLoginWrapper>
        {!!user && (
          <LinkLogoutWrapper onClick={handleLogoutUser}>
            <Logout>{__('Odhlásiť sa')}</Logout>
          </LinkLogoutWrapper>
        )}
      </MobileLogoutWrapper>
      <WNameLinkWrapper>
        {user ? (
          <>
            <LinkIconWrapper to={profileLink}>
              <MyAccountText>
                {user.firma || user.name || __('Môj účet')}
                {user && user.originalUser ? ` (${user.outer_id})` : ''}
              </MyAccountText>
            </LinkIconWrapper>
            {user && user.originalUser ? (
              <MyAccountText>
                <a
                  href={`/manager?customerId=${user.outer_id}-${user.id}&customerAddressId=`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report
                </a>
              </MyAccountText>
            ) : null}
            <LinkLogoutWrapper onClick={handleLogoutUser}>
              <Logout>{__('Odhlásiť sa')}</Logout>
            </LinkLogoutWrapper>
            {/* {isLoadingPrices && (
              <LoaderWrapper title={__('Prebieha načítanie cien')}>
                <Loader small={true} primaryColor={true} />
              </LoaderWrapper>
            )} */}
          </>
        ) : (
          <LinkLoginWrapper to={__r('routes:prihlasenie', '/prihlasenie')}>
            <UserName>{__('Prihlásiť sa')}</UserName>
          </LinkLoginWrapper>
        )}
      </WNameLinkWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-right: ${rem(32)};
  height: ${rem(48)};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease;
  white-space: nowrap;
  /* width: ${rem(300)}; */
  ${({ theme }) => theme.mediab.l1150`
    margin-right: ${rem(24)};
  `}
`;

const WNameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};
  position: relative;

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const LinkIconWrapper = styled(Link)`
  margin-top: ${rem(4)};
  text-decoration: none;
  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const UserName = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
`;

const MyAccountText = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
  margin-right: ${rem(8)};

  &:hover {
    text-decoration: underline;
  }
`;
const Logout = styled(TextP)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;
const LinkLoginWrapper = styled(Link)`
  text-decoration: none;
  position: relative;
  text-align: center;
`;
const LinkLogoutWrapper = styled(Link)`
  &:hover p {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: -${rem(20)};
  right: -${rem(12)};
`;

const MobileLoaderWrapper = styled.div`
  display: none;
  position: absolute;
  top: -${rem(32)};
  right: -${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    display: block;
  `}
`;

const MobileLogoutWrapper = styled(FlexCol)`
  display: none;

  ${({ theme }) => theme.mediab.l1150`
    display: flex;
  `}
`;

const DesktopLogoutWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.l1150`
  display: none;
`}
`;

export default HeaderProfile;
